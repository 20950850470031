<template>
  <div class="mb-2">
    <b-form>
      <validation-observer ref="createCreditNoteForm">
        <portal to="body-top">
          <h3 class="text-center font-weight-bolder mb-1">
            {{ $t('Request for Credit Note') }}
          </h3>
          <quote-status-bar
            v-if="customerInformation.id"
            :item="customerInformation"
            :quote-status-bar="quoteStatusBar"
          />
        </portal>

        <order-information />
        <credit />
        <totals class="mt-1" />.
        <form-bottom :form-refs="$refs" />
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import Credit from '@/views/main/orders/view/credit-note/components/Credit.vue'
import FormBottom from '@/views/main/orders/view/credit-note/components/FormBottom.vue'
import Totals from '@/views/main/orders/view/credit-note/components/Totals.vue'
import OrderInformation from '@/views/main/orders/view/credit-note/components/OrderInformation.vue'
import { BForm } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationObserver } from 'vee-validate'
import config from '../config'

export default {
  name: 'Paid',
  components: {
    BForm,
    OrderInformation,
    Totals,
    FormBottom,
    Credit,
    QuoteStatusBar,
    ValidationObserver,
  },
  computed: {
    customerInformation() {
      return this.$store.state[this.MODULE_NAME].customerInformationForm
    },
    quote() {
      return this.$store.state[this.MODULE_NAME].quote
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(({ data: { data } }) => {
      // this.mapDataOnGetItem(data, this)

      this.$store.commit(`${this.MODULE_NAME}/SET_CUSTOMER_INFORMATION_FORM`, data)
    })
  },
  setup() {
    const { quoteStatusBar, MODULE_NAME } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>
